import request from '@/utils/request';

export function getUserCookBookList(data) {
    return request({
        url: '/user_cook_book/list',
        method: 'post',
        data: data
    });
}

export function getUserCookBookInfo(id) {
    return request({
        url: '/user_cook_book/info/' + id,
        method: 'get',
    });
}

export function addUserCookBook(data) {
    return request({
        url: '/user_cook_book/add',
        method: 'post',
        data: data
    });
}

export function editUserCookBook(id, data){
    return request({
        url: '/user_cook_book/edit/' + id,
        method: 'put',
        data: data
    });
}

export function delUserCookBook(data) {
    return request({
        url: '/user_cook_book/del',
        method: 'post',
        data: data
    });
}
